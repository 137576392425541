// mostly copied from https://github.com/jofftiquez/vue-media-query-mixin
const mediaQuery = {
  xs: {
    max: 1099,
  },
  sm: {
    min: 1100,
    max: 1300,
  },
  md: {
    min: 1301,
    max: 1499,
  },
  lg: {
    min: 1500,
    max: 1999,
  },
  xl: {
    min: 2000,
  },
}

export default {
  data: function () {
    return {
      windowWidth: 0,
      wXS: false,
      wSM: false,
      wMD: false,
      wLG: false,
      wXL: false,
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getWindowWidth)
      this.getWindowWidth()
    })
  },
  computed: {
    viewIsSM () {
      return this.windowWidth <= mediaQuery.sm.max
    },
    viewIsSMmin () {
      return this.windowWidth <= mediaQuery.sm.min
    },
    viewIsMD () {
      return this.windowWidth >= mediaQuery.md.min && this.windowWidth <= mediaQuery.md.max
    },
  },
  methods: {
    getWindowWidth () {
      const w = window.innerWidth
      this.windowWidth = w
      this.wXS = w <= mediaQuery.xs.max
      this.wSM = w >= mediaQuery.sm.min && w <= mediaQuery.sm.max
      this.wMD = w >= mediaQuery.md.min && w <= mediaQuery.md.max
      this.wLG = w >= mediaQuery.lg.min && w <= mediaQuery.lg.max
      this.wXL = w >= mediaQuery.xl.min
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getWindowWidth)
  },
}
