<template>
  <div
    class="avatar"
    :class="[{'sleeping': sleepStatus}, `sleep${size}`]"
    :style="wrapperStyle"
  >
    <img
      :src="avatarUrl"
      :class="imgClass"
      style="height: 100%"
      :style="imgStyle"
    >
  </div>
</template>

<script>

export default {
  props: {
    url: {
      type: String,
      default: null,
    },
    size: {
      type: Number,
      default: 35,
    },
    sleepStatus: {
      type: Number,
      default: 0,
    },
    imgClass: {
      type: String,
      default: '',
    },
    round: {
      type: Boolean,
      default: false,
    },
    autoScale: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    avatarUrl () {
      const prefix = {
        35: 'mini_q_',
        50: '50_q_',
        130: '130_q_',
      }[this.size] || ''
      if (this.url) {
        if (this.url.startsWith('/api/uploads/')) {
          // path for pictures uploaded with the new API
          return this.url + `?w=${this.size}&h=${this.size}`
        } else {
          // backward compatible path for old pictures
          return '/images/' + prefix + this.url
        }
      } else {
        return '/img/' + prefix + 'avatar.png'
      }
    },
    wrapperStyle () {
      const styles = {}
      if (this.autoScale) {
        styles.height = '100%'
        styles.width = 'auto'
      }
      return styles
    },
    imgStyle () {
      const styles = {}
      styles['border-radius'] = this.round ? '50%' : '5px'
      return styles
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  position: relative;
  display: inline-block;
  background-size: cover;
}

.sleeping::after {
  content: '';
  display: block;
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.sleep35::after {
  background-image: url('/img/sleep35x35.png');
  top: -10px;
  left: -10px;
}

.sleep50::after {
  background-image: url('/img/sleep50x50.png');
  top: -10px;
  left: -20px;
}

.sleep130::after {
  background-image: url('/img/sleep130x130.png');
  top: -10px;
  left: -20px;
}
</style>
