<template>
  <!-- eslint-disable-next-line vue/max-attributes-per-line -->
  <b-container
    fluid
    class="bootstrap bg-white pt-5 pb-5"
  >
    <b-container>
      <div class="line pt-3 pt-xl-5" />
      <b-row class="pt-3">
        <b-col
          xl="8"
          cols="6"
        >
          <b-row>
            <b-col
              xl="4"
              sm="8"
              cols="3"
            >
              <a
                :href="$url('imprint')"
                :aria-label="$i18n('footer.imprint')"
                class="pr-sm-4"
              >
                {{ $i18n('footer.imprint') }}</a>
              <a
                :href="$url('dataprivacy')"
                :aria-label="$i18n('footer.dataprivacy')"
              >
                {{ $i18n('footer.dataprivacy') }}
              </a>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          xl="4"
          cols="6"
          class="d-flex justify-content-end"
        >
          <p>
            <a
              v-if="isBeta || isDev"
              :href="betaTestingIssues"
            >
              {{ $i18n('footer.beta_testing_issues') }}
            </a>
            <a
              v-else
              :href="$url('releaseNotes')"
            >
              {{ $i18n('releases.2022-05') }}
            </a>
            <br>
            <a :href="$url('devdocs')">
              {{ $i18n('footer.it_devdocs') }}
            </a>
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="text-right text-truncate">
            <span
              v-if="srcRevision && isBeta"
              class="rev-link"
            >
              <a :href="revisionLink">
                {{ $i18n('footer.version', {version: srcRevision}) }}
              </a>
            </span>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
export default {
  props: {
    isFsDotAt: {
      type: Boolean,
      default: false,
    },
    srcRevision: {
      type: String,
      default: null,
    },
    isBeta: {
      type: Boolean,
      default: false,
    },
    isDev: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    revisionLink () {
      return this.isBeta ? 'https://gitlab.com/foodsharing-dev/foodsharing/tree/' + this.srcRevision : ''
    },
    facebookUrl () {
      return this.isFsDotAt ? 'https://www.facebook.com/oesterreichfoodsharing' : 'https://www.facebook.com/foodsharing.de'
    },
    instagramUrl () {
      return this.isFsDotAt ? 'https://instagram.com/foodsharing.at' : 'https://www.instagram.com/foodsharing_de'
    },
    twitterUrl () {
      return 'https://twitter.com/FoodsharingDE'
    },
    linkedinUrl () {
      return 'https://www.linkedin.com/company/foodsharingde'
    },
    youtubeUrl () {
      return 'https://www.youtube.com/user/foodsharingtv'
    },
    betaTestingIssues () {
      return 'https://beta.foodsharing.de/?page=bezirk&bid=734&sub=forum'
    },
    externalLink () {
      return 'nofollow noreferrer noopener'
    },
  },
}
</script>

<style lang="scss" scoped>
.bootstrap .nav-link {
  padding: unset;
}

.social_icons {
  color: var(--fs-light-grey);
  font-size: 1.2rem;
}

ul {
  list-style-type: none;
  margin-left: 0;
}

h2 {
  font-family: Alfa Slab One,serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.1rem;
  color: var(--fs-dark-grey);
}

.line {
  border-bottom: 1px solid var(--fs-dark-grey);
}

a, p, li {
  font-size: 1rem;
  line-height: 2rem;
  color: var(--fs-black);
}

p {
  line-height: 1rem;
}
</style>
