import 'css/pure/pure.min.css'
import 'css/pure/grids-responsive-min.css'
import '@fortawesome/fontawesome-free/css/all.css'
import 'css/jquery-ui.css'
import 'js/fancybox/jquery.fancybox.css'
import 'css/style.css'
import 'css/content.css'
import 'js/tagedit/css/jquery.tagedit.css'
import 'css/tagedit.css'
import 'css/chat.css'
import 'typeface-alfa-slab-one'
